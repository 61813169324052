import '../Stylesheets/Scissors.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScissors } from '@fortawesome/free-solid-svg-icons';
 
 
export default function Scissors({rotate}) {
 
    return(
      rotate?
            <div className='Scissorsrotated'>
            <FontAwesomeIcon  icon={faScissors} />
            <FontAwesomeIcon icon={faScissors} />
            <FontAwesomeIcon icon={faScissors} />
            <FontAwesomeIcon icon={faScissors} />
            <FontAwesomeIcon icon={faScissors} />
            <FontAwesomeIcon icon={faScissors} />
            <FontAwesomeIcon icon={faScissors} />
            <FontAwesomeIcon icon={faScissors} />
            <FontAwesomeIcon icon={faScissors} />
            <FontAwesomeIcon icon={faScissors} />
            <FontAwesomeIcon icon={faScissors} />
            <FontAwesomeIcon icon={faScissors} />
            <FontAwesomeIcon  icon={faScissors} />
            <FontAwesomeIcon icon={faScissors} />
            <FontAwesomeIcon icon={faScissors} />
            <FontAwesomeIcon icon={faScissors} />
            <FontAwesomeIcon icon={faScissors} />
            <FontAwesomeIcon icon={faScissors} />
            <FontAwesomeIcon icon={faScissors} />
            <FontAwesomeIcon icon={faScissors} />
            <FontAwesomeIcon icon={faScissors} />
            <FontAwesomeIcon icon={faScissors} />
            <FontAwesomeIcon icon={faScissors} />
            <FontAwesomeIcon icon={faScissors} />
            <FontAwesomeIcon  icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
            
       
        </div>:
                <div className='Scissors'>
                <FontAwesomeIcon  icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon  icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon  icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />
                <FontAwesomeIcon icon={faScissors} />   
                
          
            </div>


        
    )

}